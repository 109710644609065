// src/pages/HelpCenter.tsx

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import { Helmet } from 'react-helmet-async';

const HelpCenterContainer = styled.div`
  background-color: var(--color-section-1-bg);
  color: var(--color-text);
  padding: 2rem 0;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 8rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 1rem 1.5rem;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  color: white;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  width: 100%;
  margin-bottom: 2rem;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const DocumentationSection = styled.div`
  margin-top: 2rem;
  color: white;
`;

const SectionTitle = styled.h3`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: white;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const RemoveAddonSection = styled.div`
  margin-top: 2rem;
  color: white;
`;

const StepWrapper = styled.div`
  margin-bottom: 2rem;

  h4 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: white;
  }

  img {
    width: 100%;
    max-width: 300px; /* Thumbnail size */
    height: auto;
    margin-top: 1rem;
    cursor: pointer;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  @media (max-width: 768px) {
    h4 {
      font-size: 1.25rem;
    }
  }
`;

const FullscreenImage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  img {
    max-width: 90%;
    max-height: 90%;
  }
`;

const FeatureWrapper = styled.div`
  margin-bottom: 2rem;

  h4 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: white;
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
  }

  ul {
    margin-top: 0.5rem;
    padding-left: 1.5rem;
    list-style-type: disc;

    li {
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: 768px) {
    h4 {
      font-size: 1.25rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
`;

const ContactSection = styled.div`
  text-align: center;
  margin-top: 2rem;
  color: white;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ContactLink = styled.a`
  color: var(--color-primary);
  text-decoration: underline;

  &:hover {
    color: var(--color-secondary);
  }
`;


interface HelpCenterProps {
  isLoggedIn: boolean;
  onLogout: () => void;
}

const HelpCenter: React.FC<HelpCenterProps> = ({ isLoggedIn, onLogout }) => {
  const [fullscreenImageSrc, setFullscreenImageSrc] = useState<string | null>(null);

  const handleImageClick = (src: string) => {
    setFullscreenImageSrc(src);
  };

  const closeFullscreen = () => {
    setFullscreenImageSrc(null);
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'ViewContent', { page: 'HelpCenter' });
    }
  }, []);

  const handleVideoInteraction = () => {
    if (window.fbq) {
      window.fbq('track', 'HelpVideoWatched', { page: 'HelpCenter' });
    }
  };

  const handleContactClick = () => {
    if (window.fbq) {
      window.fbq('track', 'HelpContactClick', { page: 'HelpCenter' });
    }
  };

  

  return (
    <HelpCenterContainer>
      <Helmet>
        <title>Download Risk Master | RiskMaster</title>
        <meta
          name="description"
          content="See RiskMaster's Documentation page, with an Installation and overview video guide."
        />
        <link rel="canonical" href="https://www.riskmaster.pro/help-center" />
      </Helmet>
      <Navigation activeSection="help-center" handleScroll={() => {}} isLoggedIn={isLoggedIn} onLogout={onLogout} />
      <ContentWrapper>
        <Title>Help Center</Title>
        <VideoContainer>
          <iframe
            src="https://www.youtube.com/embed/nY-M8KC93mg"
            title="Help Center Video"
            frameBorder="0"
            allowFullScreen
            onLoad={handleVideoInteraction}
          />
        </VideoContainer>
        <DocumentationSection>
          <RemoveAddonSection>
            <SectionTitle>How to Remove the RiskMaster Addon from NinjaTrader</SectionTitle>
            <StepWrapper>
              <h4>Step 1: Go to Tools &rarr; Remove NinjaScript Assembly</h4>
              <img
                src="/assets/removeAddon/remove1.jpg"
                alt="Navigate to Remove NinjaScript Assembly"
                onClick={() => handleImageClick('/assets/removeAddon/remove1.jpg')}
              />
            </StepWrapper>
            <StepWrapper>
              <h4>Step 2: Select the current version of RiskMaster and AgileDotNet.VMRuntime and press "Remove"</h4>
              <img
                src="/assets/removeAddon/remove2.jpg"
                alt="Select RiskMaster and VMRuntime for Removal"
                onClick={() => handleImageClick('/assets/removeAddon/remove2.jpg')}
              />
            </StepWrapper>
            <StepWrapper>
              <h4>Step 3: Press "Yes" to confirm removal</h4>
              <img
                src="/assets/removeAddon/remove3.jpg"
                alt="Confirm Removal"
                onClick={() => handleImageClick('/assets/removeAddon/remove3.jpg')}
              />
            </StepWrapper>
            <StepWrapper>
              <h4>Step 4: Press "Ok" and restart NinjaTrader for the addon to be removed</h4>
              <img
                src="/assets/removeAddon/remove4.jpg"
                alt="Restart NinjaTrader to Finalize Removal"
                onClick={() => handleImageClick('/assets/removeAddon/remove4.jpg')}
              />
            </StepWrapper>
          </RemoveAddonSection>
          <SectionTitle>Features and Functionality</SectionTitle>
          <FeatureWrapper>
            <h4>Rules Tab</h4>
            <p>
              The Rules Tab is where you define trading profiles with specific risk management features. These profiles
              can then be assigned to accounts to ensure trading discipline. Here are the features you can configure:
              <ul>
                <li>
                <strong>Max Allowed Contracts:</strong> This feature limits the maximum number of contracts per order, regardless of the order type. It applies to market orders, limit orders, stop-loss orders, and stop-limit orders. For example:
                <ul>
                  <li>If set to <strong>5 contracts</strong>, you cannot place a sell limit or stop-loss order exceeding 5 contracts in total.</li>
                  <li>This ensures consistency and prevents overexposure across all trading strategies.</li>
                  <li>The Reverse Button is premitted, but removes all current open orders</li>
                </ul>
                </li>
                <li>
                  <strong>Daily Trade Limit:</strong> Specify the maximum number of trades allowed per day. For instance,
                  setting the limit to <strong>10 trades</strong> ensures that trading stops after the 10th trade for
                  the day.
                </li>
                <li>
                  <strong>Consecutive Loss Limit:</strong> Limit the number of consecutive losing trades. For example, if
                  the limit is set to <strong>3 losing trades</strong>, the account will be locked after 3 losses in a
                  row to prevent further damage.
                </li>
                <li>
                  <strong>Daily Loss Threshold:</strong> Set the maximum loss allowable for the day. If the account
                  incurs losses equal to or greater than the threshold (e.g., <strong>$1,000</strong>), all trades are
                  closed and the account is locked.
                </li>
                <li>
                  <strong>Daily Profit Target:</strong> Define a profit target for the day. Reaching the target (e.g.,
                  <strong>$500</strong>) closes all trades and locks the account to secure gains.
                </li>
                <li>
                  <strong>Single Trade Loss Limit:</strong> Automatically close trades if losses exceed the specified
                  amount (e.g., <strong>$200</strong>) for a single trade.
                </li>
                <li>
                  <strong>Single Trade Profit Target:</strong> Automatically close trades if profits exceed the specified
                  amount (e.g., <strong>$200</strong>) for a single trade.
                </li>
                <li>
                  <strong>Allowed Trading Days:</strong> Choose specific days of the week for trading. For instance, if
                  only <strong>Monday to Friday</strong> are selected, trading will be disabled on weekends.
                </li>
                <li>
                  <strong>Time Restriction Window:</strong> Set specific trading hours during which trading is allowed.
                  <ul>
                    <li>
                      This feature uses your <strong>local computer time</strong> to define specific trading hours. For example, if you set trading to be allowed from <strong>9:30 AM to 4:00 PM</strong>, trading will only be enabled during these hours based on your computer's clock.
                    </li>
                  </ul>
                  
                </li>
              </ul>
            </p>
          </FeatureWrapper>
          <FeatureWrapper>
            <h4>Profiles Tab</h4>
            <p>
              The Profiles Tab lets you manage your risk management profiles. Each profile is a collection of rules configured in the Rules Tab. Here are the options available:
              <ul>
                <li>
                  <strong>View Profiles:</strong> See all existing profiles along with their configured rules, such as trading limits, time restrictions, and assigned accounts.
                </li>
                <li>
                  <strong>Edit Profiles:</strong> Modify an existing profile to update its settings. For example, you can increase the daily trade limit or change the allowed trading days.
                  <strong> Changing a profile’s settings will not affect accounts that currently have the profile assigned for the rest of the trading day. The updated settings will only take effect starting from the next trading day.</strong>
                </li>
                <li>
                  <strong>Remove Profiles:</strong> Delete profiles that are no longer needed to keep your list clean and relevant.
                  <strong> Deleting a profile will not remove its settings from accounts that currently have the profile assigned for the rest of the trading day. The profile and its settings will only be removed from those accounts starting from the next trading day.</strong>
                  <strong>Note that deleting a profile will not cancel its settings for accounts that have this profile attached to them, only on tomorrows trading day.</strong>
                </li>
                <li>
                  <strong>Assign Profiles:</strong> Apply a profile to a specific account to enforce its restrictions immediately. <strong>Assigning a profile will apply the profile for both today and tomorrow. However, if the account already has a profile assigned for today, the newly assigned profile will only take effect starting tomorrow.</strong>
                </li>
              </ul>
            </p>
          </FeatureWrapper>
          <FeatureWrapper>
            <h4>Accounts Tab</h4>
            <p>
              The Accounts Tab provides an overview of all connected trading accounts and allows you to manage restrictions and profiles. Key features include:
              <ul>
                <li>
                  <strong>Lock Account:</strong> Manually lock an account to prevent trading. Locked accounts remain restricted until the start of the next trading day (6:00 PM ET). For example, if you lock an account on Monday at 5:00 PM ET, it will remain locked until Tuesday's trading day begins.
                </li>
                <li>
                  <strong>Assign Profile:</strong> Assign a profile from the Profiles Tab to an account to apply its rules.
                </li>
                <li>
                  <strong>Remove Restrictions:</strong> Use the "Remove" button to lift restrictions from a single account or "Remove All" to lift restrictions from all accounts simultaneously. This action can only be performed using the Third-Party Email feature, which allows a trusted individual to manage account restrictions. To understand how this works, please refer to the <strong>Settings Tab</strong> section on <strong>Third-Party Email</strong>.
                  <ul>
                    <li>
                      <strong>What Is Removed? :</strong>
                      <ul>
                        <li>
                          Assigned profile for the current trading day
                        </li>
                        <li>
                          All account restrictions
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Reset Account:</strong> Reset an account that has an assigned profile for the next trading day. Use "Reset All" to reset all accounts at once.
                </li>
              </ul>
            </p>
          </FeatureWrapper>
          <FeatureWrapper>
            <h4>Settings Tab</h4>
            <p>
              The Settings Tab is where you configure system settings and manage permissions:
              <ul>
                <li>
                  <strong>License Status:</strong> Displays whether your license is active or inactive. For example, "Active" means all features are enabled.
                </li>
                <li>
                  <strong>Assign Email:</strong> Add your email address for support purposes. This email can also be used to recover account access.
                </li>
                <li>
                  <strong>Third-Party Email:</strong> Add a trusted email (e.g., a mentor, family member, or partner) to grant them permissions to manage restrictions using "Remove" or "Remove All in the Accoutns Tab."
                </li>
              </ul>
            </p>
          </FeatureWrapper>
          <FeatureWrapper>
            <h4>Time Restriction Window vs. New Trading Day</h4>
            <p>
              <strong>Time Restriction Window:</strong> This feature uses your <strong>local computer time</strong> to define specific trading hours. For example, if you set trading to be allowed from <strong>9:30 AM to 4:00 PM</strong>, trading will only be enabled during these hours based on your computer's clock.
            </p>
            <p>
              <strong>New Trading Day:</strong> This is based on <strong>Eastern Time (ET)</strong> and starts at <strong>6:00 PM ET</strong>. The account restrictions reset automatically at the start of the new trading day. For instance, if an account is locked on Monday, it will unlock at 6:00 PM ET the same day to begin the next trading day.
            </p>
            <p>
              <strong>Key Difference:</strong> Time Restriction Window enforces specific trading hours during the day based on local time, while New Trading Day determines when account resets occur and is always based on ET.
            </p>
          </FeatureWrapper>
        </DocumentationSection>
        {fullscreenImageSrc && (
          <FullscreenImage onClick={closeFullscreen}>
            <img src={fullscreenImageSrc} alt="Expanded view" />
          </FullscreenImage>
        )}
        <ContactSection>
          <h3>Contact Us</h3>
          <p>If you have any questions, feel free to reach out to us at:</p>
          <ContactLink href="mailto:support@riskmaster.pro" onClick={handleContactClick}>
            support@riskmaster.pro
          </ContactLink>
        </ContactSection>
      </ContentWrapper>
      <Footer />
    </HelpCenterContainer>
  );
};

export default HelpCenter;
